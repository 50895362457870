<template>
  <div class="aubot">
    <div class="aubot-banner">
      <img :src="require('@imgs/vrbuild/vrbuild.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/vrbuild/web/vrbuild.png')" v-else />
      <div class="aubot-banner-title">
        <h3>VR专业建设</h3>
        <!-- <span> VR PROFESSIONAL CONSTRUCTION</span> -->
      </div>
    </div>
    <div class="aubot-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: '/vr-build' })">VR专业建设</span>
         <!-- -> {{ isActive }} -->
    </div>
    <div class="aubot-content">
      <vr-content :isPage="isPage" :isPageSize="4"></vr-content>
    </div>
    <div class="aubot-block">
      <div class="aubot-block-header">
        <div class="aubot-block-new-title">热门推荐</div>
        <div class="aubot-block-pages">
          <div class="aubot-block-pages-item" @click="$refs.swiper.pre()">
            <img :src="require('@imgs/searchback.png')" />
          </div>
          <div class="aubot-block-pages-item" @click="$refs.swiper.next()">
            <img :src="require('@imgs/listmore.png')" />
          </div>
        </div>
      </div>
      <swiper ref="swiper" :autoInit="false">
        <swiper-slide v-for="(item, index) in moreList" :key="index">
          <vr-class-item :info="item"></vr-class-item>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import swiper from '@components/swiper/swiper'
import swiperSlide from '@components/swiper/swiperSlide'
import vrClassItem from '../vrClass/vr-class-item.vue'
import vrContent from './vr-build-content'
export default {
  name: 'VRBuild',
  props: {
    isPage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    vrContent,
    vrClassItem,
    swiper,
    swiperSlide
  },
  data() {
    return {
      tabs: [{
        name: 'VR实训室'
      }, {
        name: 'VR教学平台'
      }, {
        name: 'VR实训工具箱'
      }, {
        name: 'VR教材'
      }, {
        name: 'VR交互系统',
      },],
      isActive: 'VR教学平台',
      moreList: []
    }
  },
  mounted() {
    this.getXg()
  },
  methods: {
    tabChange(item) {
      this.isActive = item
    },
    getXg() {
      this.$http({
        url: `hot/list`
      }).then((res) => {
        this.moreList = res.rows || []
        this.$nextTick(() => {
          this.$refs.swiper.init({
            slidesPerView: this.$isMobile() ? 1 : 4,
            loop: false,
            autoplay: false
          })
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.aubot {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-tabs-item {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-tabs-item--active {
    background: #0862ca;
    color: #fff;
  }

  &-block {
    max-width: 1200px;
    margin: 20px auto 40px auto;
    background: #fff;
    padding: 24px;

    &-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    &-title {
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
      display: flex;
      margin-bottom: 20px;
    }

    &-new {
      &-title {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        position: relative;
        padding-left: 10px;
        font-family: Microsoft YaHei;
      }

      &-title::before {
        content: "";
        width: 4px;
        border-radius: 2px;
        height: 20px;
        left: 0px;
        position: absolute;
        background: #0862ca;
        display: block;
        top: 3px;
      }

      &-more {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        cursor: pointer;
        font-family: Microsoft YaHei;
      }
    }

    &-pages {
      display: flex;
      justify-content: flex-end;

      &-item {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-item:hover,
      &-item:active {
        background: #0862ca;
      }

      img {
        width: 12px;
      }
    }
  }
}

.aubot-vrbuild-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 270px;
    height: 191px;
  }

  span {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    padding: 15px 0;
    font-family: Microsoft YaHei;
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>